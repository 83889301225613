@tailwind base;
@tailwind components;
@tailwind utilities;

body{
 background-color: #F2F3F4;   
}

.font-link{
    font-family:"Oswald", sans-serif;
    font-optical-sizing: auto;
    font-weight: weight;
    font-style: normal;
}

.slick-slide {
    transition: ease-in-out 1s; /* You can adjust the duration as needed */
  }

/* #F5F5F7 */